import { ApplicationData } from "types"
import { optionize } from "data/utils"
import { HOLLINS_MAJORS } from "data/colleges/hollins/hollins_options"

export const assetsUrl =
  "https://apollolandingpagesprod.blob.core.windows.net/$web/hollins/application/ca-assets/assets/72310d45-77b4-406f-a459-b04b9b7c153d/"

const favicon = "20240613172550_favicon.png"
const logo = "20240613172548_ca-hollins-logo.svg"

export const HOLLINS_DATA: ApplicationData = {
  layout: {
    version: "1",
  },
  meta: {
    title: "Hollins University",
    contact: {
      email: "huadm@hollins.edu",
      phone: "800-456-9595",
      website: "https://www.hollins.edu/privacy-policy/",
    },
    logo: assetsUrl + logo,
    footerLogo: assetsUrl + logo,
    favicon: assetsUrl + favicon,
    name: "Hollins University",
    submissionRedirectUrl:
      "https://www.hollins-university.org/application/submitted",
  },
  susiPages: {
    signUp: {
      heading: `Your Hollins Trailblazer Application`,
      formHeading: "Sign Up",
      htmlContent: `
          <div>
            <section>
              <p>
              The Hollins Trailblazer Application cuts out all the fluff–it's easy to submit! When you apply, you get the following benefits:  
              </p>
              <ul>
                <li>No application fee</li>
                <li>Test optional</li>
                <li>Essay optional</li>
                <li>Automatic merit scholarship consideration</li>
              </ul>
            </section>
            <figure class="sign-up-image" aria-hidden="true">
              <div id="hero-background">
                <div id="hero-image"></div>
              </div>
            </figure>
          </div>
          `,
    },
    signIn: {
      heading: "",
      formHeading: "Log In",
      htmlContent: `
        <div>
          <figure class="sign-in-image" aria-hidden="true">
            <div id="hero-background">
              <div id="hero-image"></div>
            </div>
          </figure>
        </div>`,
    },

    forgotPassword: {
      heading: "",
      formHeading: "Reset Password",
      htmlContent: `
        <div>
          <figure class="sign-in-image" aria-hidden="true">
            <div id="hero-background">
              <div id="hero-image"></div>
            </div>
          </figure>
        </div>`,
    },
  },
  pages: [
    {
      id: "about-you",
      slug: "about-you",
      title: "About You",
      mobileNavTitle: "1. About You",
      description: "We'd like to get to know you better.",
      sections: [
        {
          title: "Application Details",
          description:
            "First, please tell us about the type of application you would like to submit to Hollins University.",
          fields: [
            {
              name: "student_app_type",
              label: "Preferred Admission Plan",
              helperText:
                "Tell us how you're applying. Alongside federal and state aid, Hollins' <a href='https://hope.hollins.edu/' target='_blank'>HOPE program</a> covers the cost of tuition, on-campus food and housing, and fees for eligible students. If Hollins is your first choice and you believe you may be eligible for HOPE, we encourage you to apply through the binding Early Decision option.",
              type: "radio",
              options: optionize([
                // "Early Decision 1",
                // "Early Action",
                // "Early Decision 2",
                "Regular Decision",
              ]),
              prefill: [
                {
                  value: "Regular Decision",
                  override: true,
                },
              ],
              hide: true,
              isRequired: true,
            },
            {
              name: "transfer_app",
              label: null,
              type: "radio",
              options: optionize(["Yes", "No"]),
              prefill: [
                {
                  value: "No",
                  override: true,
                },
              ],
              hide: true,
            },
            {
              name: "student_housing_lives_locally",
              label: "Do you live within 40 miles from Hollins?",
              type: "radio",
              options: optionize([
                ["Yes, I live within 40 miles from Hollins.", "Yes"],
                ["No, I do not live within 40 miles from Hollins.", "No"],
              ]),
              isRequired: true,
            },
            {
              name: "student_housing_on_campus",
              label: "Do you want to live on campus?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              show: {
                conditions: [
                  {
                    name: "student_housing_lives_locally",
                    value: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "student_housing_lives_locally",
                    value: "Yes",
                  },
                ],
              },
            },
            {
              name: "student_housing_message_only",
              label: null,
              type: "checkbox",
              options: optionize([
                ["I understand I will be required to live on-campus.", "Yes"],
              ]),
              show: {
                conditions: [
                  {
                    name: "student_housing_lives_locally",
                    value: "No",
                  },
                ],
              },
              isRequired: true,
            },
          ],
        },
        {
          title: "You",
          description: "",
          fields: [
            {
              name: "first_name",
              type: "text",
              label: "First/Given Name",
              isRequired: true,
              group: 1,
              order: 1,
            },
            {
              name: "middle_name",
              type: "text",
              label: "Middle Name",
              group: 1,
              order: 2,
            },
            {
              name: "last_name",
              type: "text",
              label: "Last/Family/Surname",
              isRequired: true,
              group: 1,
              order: 3,
            },
            {
              name: "preferred_name",
              type: "text",
              label: "Preferred First Name",
              helperText:
                "What first name do you want us to use in all communication to you and your family?",
            },
            {
              name: "date_of_birth",
              type: "date",
              label: "Date of Birth",
              isRequired: true,
              validations: {
                date: {
                  maxYearsInPast: 100,
                  isInPast: true,
                },
              },
            },
            {
              name: "sex",
              type: "radio",
              label: "Legal Sex",
              options: optionize(["Female", "Male", "X or another legal sex"]),
              isRequired: true,
            },
            {
              name: "other_sex",
              type: "text",
              label: "Your legal sex",
              show: {
                conditions: [
                  {
                    name: "sex",
                    value: "X or another legal sex",
                  },
                ],
              },
            },
            {
              name: "gender_statement",
              type: "checkbox",
              label:
                "Yes, I identify as a woman and seek an outstanding liberal arts education in a unique environment designed and implemented to serve women. I affirm that I consistently live and identify as a woman.",
              options: optionize(["Yes"]),
              isRequired: true,
            },
            {
              name: "gender_identity",
              type: "radio",
              label: "Gender Identity",
              options: optionize(["Female", "Male", "Nonbinary", "Other"]),
            },
            {
              name: "other_gender",
              type: "text",
              label: "Your gender identity",
              show: {
                conditions: [
                  {
                    name: "gender_identity",
                    value: "Other",
                  },
                ],
              },
            },
            {
              name: "preferred_pronouns",
              type: "radio",
              label: "Pronouns",
              options: optionize([
                "He / Him / His",
                "She / Her / Hers",
                "They / Them / Theirs",
                "Other",
              ]),
            },
            {
              name: "other_pronouns",
              type: "text",
              label: "Your preferred pronouns",
              show: {
                conditions: [
                  {
                    name: "preferred_pronouns",
                    value: "Other",
                  },
                ],
              },
            },
          ],
        },
        {
          title: "Your Background",
          description: "We'd like to get to know you better.",
          fields: [
            {
              name: "hispanic",
              label: "Are you Hispanic or Latino/a/x?",
              type: "radio",
              options: optionize(["Yes", "No"]),
            },
            {
              name: "race",
              label: "Your Race",
              helperText:
                "No matter how you answer the question above, please let us know how you describe yourself.",
              type: "checkbox",
              options: optionize([
                "American Indian or Alaska Native",
                "Asian",
                "Black or African American",
                "Native Hawaiian or Other Pacific Islander",
                "White",
                "Prefer not to say",
              ]),
            },
          ],
        },
        {
          title: "Citizenship",
          description: "",
          fields: [
            {
              name: "citizenship",
              label: "Your Citizenship",
              helperText:
                "U.S. citizenship is not a requirement for admission. Your response may prompt additional questions you'll need to answer.",
              type: "radio",
              options: optionize([
                "U.S. Citizen or U.S. National",
                "U.S. Dual Citizen",
                "U.S. Permanent Resident",
                "Not a U.S. Citizen",
              ]),
              isRequired: true,
            },
            {
              name: "birth_country",
              label: "Birth Country",
              helperText: "Start typing to find and select a country.",
              type: "countrySearch",
              show: {
                conditions: [
                  {
                    name: "citizenship",
                    notValue: "U.S. Citizen or U.S. National",
                  },
                  {
                    name: "citizenship",
                    notValue: "",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "citizenship",
                    value: "U.S. Permanent Resident",
                  },
                  {
                    name: "citizenship",
                    value: "Not a U.S. Citizen",
                  },
                ],
                logicalOr: true,
              },
            },
            {
              name: "primary_language",
              label: "What language is spoken at home?",
              helperText: "Start typing to find and select a language.",
              type: "languageSearch",
              show: {
                conditions: [
                  {
                    name: "citizenship",
                    value: "U.S. Dual Citizen",
                  },
                  {
                    name: "citizenship",
                    value: "U.S. Permanent Resident",
                  },
                  {
                    name: "citizenship",
                    value: "Not a U.S. Citizen",
                  },
                ],
                logicalOr: true,
              },
            },
            {
              name: "citizenship_country",
              label: "In which other country are you a citizen?",
              helperText: "Start typing to find and select a country.",
              type: "countrySearch",
              show: {
                conditions: [
                  {
                    name: "citizenship",
                    value: "U.S. Dual Citizen",
                  },
                  {
                    name: "citizenship",
                    value: "U.S. Permanent Resident",
                  },
                  {
                    name: "citizenship",
                    value: "Not a U.S. Citizen",
                  },
                ],
                logicalOr: true,
              },
            },
            {
              name: "visa_type",
              label: "Visa Type",
              type: "select",
              options: optionize([
                "A-1",
                "A-2",
                "A-3",
                "B-2",
                "E-2",
                "F-1",
                "F-2",
                "G-1",
                "G-2",
                "G-3",
                "G-4",
                "H-4",
                "J-1",
                "J-2",
                "L-2",
                "R-2",
              ]),
              show: {
                conditions: [
                  {
                    name: "citizenship",
                    value: "Not a U.S. Citizen",
                  },
                ],
              },
            },
          ],
        },
        {
          title: "TOEFL or IELTS",
          show: {
            conditions: [
              {
                name: "citizenship",
                value: "Not a U.S. Citizen",
              },
            ],
          },
          fields: [
            {
              name: "toefl_considered",
              label: "Have you taken or will you take the TOEFL?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              show: {
                conditions: [
                  {
                    name: "citizenship",
                    value: "Not a U.S. Citizen",
                  },
                ],
              },
              isRequired: true,
            },
            {
              name: "toefl_date_taken",
              label: "TOEFL Date Taken or Date when TOEFL will be taken",
              type: "month",
              validations: {
                date: {
                  maxYearsInPast: 5,
                  maxYearsInFuture: 5,
                },
              },
              show: {
                conditions: [
                  {
                    name: "toefl_considered",
                    value: "Yes",
                  },
                ],
              },
              isRequired: true,
            },
            {
              name: "toefl_highest_score",
              label: "TOEFL Highest Score",
              type: "integer",
              show: {
                conditions: [
                  {
                    name: "toefl_considered",
                    value: "Yes",
                  },
                ],
              },
              validations: {
                value: {
                  min: 0,
                  max: 120,
                },
              },
            },

            {
              name: "ielts_considered",
              label: "Have you taken or will you take the IELTS?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              show: {
                conditions: [
                  {
                    name: "citizenship",
                    value: "Not a U.S. Citizen",
                  },
                ],
              },
              isRequired: true,
            },
            {
              name: "ielts_date_taken",
              label: "IELTS Date Taken or Date when IELTS will be taken",
              type: "month",
              validations: {
                date: {
                  maxYearsInPast: 5,
                  maxYearsInFuture: 5,
                },
              },
              show: {
                conditions: [
                  {
                    name: "ielts_considered",
                    value: "Yes",
                  },
                ],
              },
              isRequired: true,
            },
            {
              name: "ielts_highest_score",
              label: "IELTS Highest Score",
              type: "integer",
              show: {
                conditions: [
                  {
                    name: "ielts_considered",
                    value: "Yes",
                  },
                ],
              },
              validations: {
                value: {
                  min: 0,
                  max: 120,
                },
              },
            },
          ],
        },
        {
          title: "Your Contact Information",
          description: "Let us know how best to reach you and where you live.",
          fields: [
            {
              name: "email_address",
              label: "Email Address",
              type: "email",
              helperText:
                "This is the email address you provided when you started your application so it can't be changed.",
            },
            {
              name: "phone_number",
              label: "Phone Number",
              type: "tel-2",
              errorText: "Please enter a valid phone number.",
              helperText:
                "Don't miss important updates! Add your cell. It's easy to opt-out later.",
            },
            {
              name: "phone_text_opt-in",
              label: null,
              type: "checkbox",
              options: optionize([
                [
                  "Yes, I'd like to receive application status and other important updates via text from Hollins University. (Standard message charges apply.)",
                  "Yes",
                ],
              ]),
            },
            {
              name: "country",
              label: "Country",
              type: "countrySearch",
              isRequired: true,
              // prefill: [
              //   {
              //     value: "United States",
              //     conditionSet: {
              //       conditions: [
              //         {
              //           name: "citizenship",
              //           value: "U.S. Dual Citizen",
              //         },
              //         {
              //           name: "citizenship",
              //           value: "U.S. Permanent Resident",
              //         },
              //         {
              //           name: "citizenship",
              //           value: "U.S. Citizen or U.S. National",
              //         },
              //       ],
              //       logicalOr: true,
              //     },
              //   },
              // ],
            },
            {
              name: "address1",
              label: "Address",
              type: "text",
              isRequired: true,
              group: 4,
              order: 1,
              width: "60%",
            },
            {
              name: "address2",
              label: "Apartment, suite, etc.",
              type: "text",
              group: 4,
              order: 2,
              width: "40%",
              show: {
                conditions: [
                  {
                    name: "citizenship",
                    value: "U.S. Dual Citizen",
                  },
                  {
                    name: "citizenship",
                    value: "U.S. Permanent Resident",
                  },
                  {
                    name: "citizenship",
                    value: "Not a U.S. Citizen",
                  },
                ],
                logicalOr: true,
              },
            },
            {
              name: "city",
              label: "City",
              type: "text",
              group: 2,
              order: 1,
              isRequired: true,
            },
            {
              name: "state",
              label: "State",
              type: "stateSearch",
              group: 2,
              order: 2,
              show: {
                conditions: [
                  {
                    name: "country",
                    value: "",
                  },
                  {
                    name: "country",
                    value: "United States",
                  },
                ],
                logicalOr: true,
              },
              isRequired: {
                conditions: [
                  {
                    name: "country",
                    value: "",
                  },
                  {
                    name: "country",
                    value: "United States",
                  },
                ],
                logicalOr: true,
              },
            },
            {
              name: "zip",
              label: "Zip Code",
              type: "zip_code",
              group: 2,
              order: 3,
              validations: {
                length: {
                  is: 5,
                },
              },
              errorText: "Zip Code is invalid",
              show: {
                conditions: [
                  {
                    name: "country",
                    value: "",
                  },
                  {
                    name: "country",
                    value: "United States",
                  },
                ],
                logicalOr: true,
              },
              isRequired: {
                conditions: [
                  {
                    name: "country",
                    value: "",
                  },
                  {
                    name: "country",
                    value: "United States",
                  },
                ],
                logicalOr: true,
              },
            },
            {
              name: "state_province",
              label: "State/Province",
              type: "text",
              group: 2,
              order: 2,
              show: {
                conditions: [
                  {
                    name: "country",
                    notValue: "",
                  },
                  {
                    name: "country",
                    notValue: "United States",
                  },
                ],
              },
            },
            {
              name: "postal_code",
              label: "Postal Code",
              type: "text",
              group: 2,
              order: 3,
              validations: {
                length: {
                  min: 5,
                  max: 15,
                },
              },
              show: {
                conditions: [
                  {
                    name: "country",
                    notValue: "",
                  },
                  {
                    name: "country",
                    notValue: "United States",
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      id: "student-family",
      slug: "student-family",
      title: "Your Family",
      mobileNavTitle: "2. Family",
      description: "Help us get to know your family.",
      sections: [
        {
          title: "Your Parents & Guardians",
          description: "Tell us about your parents and/or guardians.",
          nestedSectionsMin: 1,
          nestedSectionsMax: 4,
          nestedSectionTitle: "Parent/Guardian {{x}}",
          nestedSectionItemName: "Parent/Guardian",
          controlSectionName: "parents",
          hide: {
            conditions: [
              {
                name: "parent_existence",
                value: "No",
              },
            ],
          },
          fields: [
            {
              name: "parent_existence",
              label: "Do you have parent and/or guardian?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              prefill: [
                {
                  value: "Yes",
                },
              ],
              hide: true,
            },
            {
              name: "parent_type",
              label: "Relationship",
              type: "radio",
              options: optionize([
                "Mother",
                "Father",
                "Legal Guardian",
                "Other",
              ]),
              isRequired: true,
            },
            {
              name: "parent_living",
              label: "Is parent/guardian living?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              isRequired: true,
            },
            {
              name: "parent_first_name",
              label: "First Name",
              type: "text",
              group: 1,
              order: 1,
              hide: {
                conditions: [
                  {
                    name: "parent_living",
                    notValue: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                ],
              },
            },
            {
              name: "parent_last_name",
              label: "Last Name",
              type: "text",
              group: 1,
              order: 2,
              hide: {
                conditions: [
                  {
                    name: "parent_living",
                    notValue: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                ],
              },
            },
            {
              name: "parent_cell_phone",
              label: "Mobile Phone",
              helperText:
                "Most applicants add a parent cell so their family doesn't miss critical info on things like financial aid.",
              type: "tel-2",
              hide: {
                conditions: [
                  {
                    name: "parent_living",
                    notValue: "Yes",
                  },
                ],
              },
            },
            {
              name: "parent_email_address",
              label: "Email Address",
              helperText:
                "We'd like to send your parent important updates about your application and about financial aid.",
              type: "email",
              hide: {
                conditions: [
                  {
                    name: "parent_living",
                    notValue: "Yes",
                  },
                ],
              },
            },
            {
              name: "parent_same_address",
              label: "Is their address the same as yours?",
              type: "radio",
              show: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                ],
              },
              options: optionize(["Yes", "No"]),
              isRequired: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                ],
              },
            },
            {
              name: "parent_country",
              label: "Country",
              type: "countrySearch",
              show: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                  {
                    name: "parent_same_address",
                    value: "No",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                  {
                    name: "parent_same_address",
                    value: "No",
                  },
                ],
              },
              // prefill: [
              //   {
              //     value: "United States",
              //     conditionSet: {
              //       conditions: [
              //         {
              //           name: "citizenship",
              //           value: "U.S. Dual Citizen",
              //         },
              //         {
              //           name: "citizenship",
              //           value: "U.S. Permanent Resident",
              //         },
              //         {
              //           name: "citizenship",
              //           value: "U.S. Citizen or U.S. National",
              //         },
              //       ],
              //       logicalOr: true,
              //     },
              //   },
              // ],
            },
            {
              name: "parent_address1",
              label: "Address",
              type: "text",
              group: 5,
              order: 1,
              width: "60%",
              show: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                  {
                    name: "parent_same_address",
                    value: "No",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                  {
                    name: "parent_same_address",
                    value: "No",
                  },
                ],
              },
            },
            {
              name: "parent_address2",
              label: "Apartment, suite, etc.",
              type: "text",
              group: 5,
              order: 2,
              width: "40%",
              show: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                  {
                    name: "parent_same_address",
                    value: "No",
                  },
                ],
              },
            },
            {
              name: "parent_city",
              label: "City",
              type: "text",
              group: 3,
              order: 1,
              show: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                  {
                    name: "parent_same_address",
                    value: "No",
                  },
                ],
              },
            },
            {
              name: "parent_state",
              label: "State",
              type: "stateSearch",
              group: 3,
              order: 2,
              show: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                  {
                    name: "parent_same_address",
                    value: "No",
                  },
                ],
              },
              hide: {
                conditions: [
                  {
                    name: "parent_country",
                    notValue: "United States",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                  {
                    name: "parent_same_address",
                    value: "No",
                  },
                ],
              },
            },
            {
              name: "parent_state_province",
              label: "State/Province",
              type: "text",
              group: 3,
              order: 2,
              show: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                  {
                    name: "parent_same_address",
                    value: "No",
                  },
                  {
                    name: "parent_country",
                    notValue: "United States",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                  {
                    name: "parent_country",
                    notValue: "United States",
                  },
                  {
                    name: "parent_country",
                    notValue: "",
                  },
                ],
              },
            },
            {
              name: "parent_zip_code",
              label: "Zip Code",
              type: "zip_code",
              group: 3,
              order: 3,
              show: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                  {
                    name: "parent_same_address",
                    value: "No",
                  },
                ],
              },
              hide: {
                conditions: [
                  {
                    name: "parent_country",
                    notValue: "United States",
                  },
                ],
              },
              validations: {
                length: {
                  is: 5,
                },
              },
              errorText: "Zip Code is invalid",
              isRequired: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                ],
              },
            },
            {
              name: "parent_postal_code",
              label: "Postal Code",
              type: "text",
              group: 3,
              order: 3,
              show: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                  {
                    name: "parent_same_address",
                    value: "No",
                  },
                  {
                    name: "parent_country",
                    notValue: "United States",
                  },
                ],
              },
              validations: {
                length: {
                  min: 5,
                  max: 15,
                },
              },
              isRequired: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                  {
                    name: "parent_country",
                    notValue: "United States",
                  },
                  {
                    name: "parent_country",
                    notValue: "",
                  },
                ],
              },
            },
            {
              name: "parent_occupation",
              label: "Occupation",
              type: "text",
              hide: {
                conditions: [
                  {
                    name: "parent_living",
                    notValue: "Yes",
                  },
                ],
              },
            },
            {
              name: "parent_employer",
              label: "Employer",
              type: "text",
              hide: {
                conditions: [
                  {
                    name: "parent_living",
                    notValue: "Yes",
                  },
                ],
              },
            },
          ],
        },
        {
          title: "",
          description: "",
          fields: [
            {
              name: "parent_degree",
              label:
                "Have any of your parents/guardians completed a four-year degree?",
              type: "radio",
              isRequired: true,
              options: optionize(["Yes", "No"]),
            },
          ],
        },
        {
          title: "Alumnae/i (Optional):",
          conditionalSection: true,
          description:
            "Has anyone else in your family attended Hollins University? You can share up to 4 family members who attended or are currently enrolled.",
          fields: [
            {
              name: "alumni_shared",
              label:
                "Are any of your family members part of the Hollins University community as alumni or current students?",
              type: "radio",
              options: optionize(["Yes", "No"]),
            },
          ],
        },
        {
          title: "Alumnae/i",
          nestedSectionsMax: 4,
          nestedSectionTitle: "Alumnae/i {{x}}",
          nestedSectionItemName: "Alumnae/i",
          controlSectionName: "alumnis",
          isOptional: true,
          hide: {
            conditions: [
              {
                name: "alumni_shared",
                value: "No",
              },
              {
                name: "alumni_shared",
                value: "",
              },
            ],
            logicalOr: true,
          },
          fields: [
            {
              name: "alumni_relationship",
              label: "Relationship",
              type: "select",
              options: optionize([
                "Mother",
                "Father",
                "Step-Mother",
                "Step-Father",
                "Brother",
                "Sister",
                "Step-Brother",
                "Step-Sister",
                "Grandparent",
                "Uncle",
                "Aunt",
                "Cousin",
                "Other",
              ]),
            },
            {
              name: "alumni_first_name",
              label: "First Name",
              type: "text",
              group: 1,
              order: 1,
            },
            {
              name: "alumni_last_name",
              label: "Last Name",
              type: "text",
              group: 1,
              order: 2,
            },
            {
              name: "alumni_grad_year",
              label: "Graduation Year",
              type: "year",
              validations: {
                date: {
                  maxYearsInPast: 100,
                  maxYearsInFuture: 5,
                },
              },
            },
            {
              name: "alumni_email_address",
              label: "Email Address",
              type: "email",
            },
          ],
        },
      ],
    },
    {
      id: "your-experience",
      slug: "your-experience",
      title: "Your Experience",
      mobileNavTitle: "3. Your Experience",
      description: "We'd like to know about your accomplishments.",
      sections: [
        {
          title: "Most Recent Education",
          description: "Your Current or Most Recent High School",
          fields: [
            {
              name: "base_institution_ceeb",
              label: "Your School",
              helperText: "Start typing to find and select a school",
              type: "highSchoolSearch",
              isRequired: true,
            },
            {
              name: "base_institution_name",
              type: "text",
              label: "School Name",
              show: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                ],
              },
            },
            {
              name: "base_institution_country",
              type: "countrySearch",
              label: "School Country",
              group: 1,
              order: 1,
              show: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                ],
              },
              // prefill: [
              //   {
              //     value: "United States",
              //     conditionSet: {
              //       conditions: [
              //         {
              //           name: "citizenship",
              //           value: "U.S. Dual Citizen",
              //         },
              //         {
              //           name: "citizenship",
              //           value: "U.S. Permanent Resident",
              //         },
              //         {
              //           name: "citizenship",
              //           value: "U.S. Citizen or U.S. National",
              //         },
              //       ],
              //       logicalOr: true,
              //     },
              //   },
              // ],
            },
            {
              name: "base_institution_state",
              type: "stateSearch",
              label: "School State",
              group: 1,
              order: 2,
              show: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                  {
                    name: "base_institution_country",
                    value: "United States",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                  {
                    name: "base_institution_country",
                    value: "United States",
                  },
                ],
              },
            },
            {
              name: "base_institution_state_province",
              label: "School State/Province",
              type: "text",
              group: 1,
              order: 2,
              show: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                  {
                    name: "base_institution_country",
                    notValue: "United States",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                  {
                    name: "base_institution_country",
                    notValue: "United States",
                  },
                ],
              },
            },
            {
              name: "base_institution_city",
              type: "text",
              label: "School City",
              group: 2,
              order: 1,
              show: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                ],
              },
            },
            {
              name: "base_institution_zip_code",
              label: "Zip Code",
              type: "zip_code",
              group: 2,
              order: 2,
              validations: {
                length: {
                  is: 5,
                },
              },
              errorText: "Zip Code is invalid",
              show: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                  {
                    name: "base_institution_country",
                    value: "United States",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                  {
                    name: "base_institution_country",
                    value: "United States",
                  },
                ],
              },
            },
            {
              name: "base_institution_postal_code",
              label: "Postal Code",
              type: "text",
              group: 2,
              order: 2,
              show: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                  {
                    name: "base_institution_country",
                    notValue: "United States",
                  },
                ],
              },
              validations: {
                length: {
                  min: 5,
                  max: 15,
                },
              },
              isRequired: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                  {
                    name: "base_institution_country",
                    notValue: "United States",
                  },
                ],
              },
            },
            {
              name: "base_institution_address1",
              type: "text",
              label: "School Address",
              show: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                ],
              },
            },
            {
              name: "base_institution_from_date",
              label: "School Start Date",
              type: "month",
              group: 3,
              order: 1,
              validations: {
                date: {
                  maxYearsInPast: 100,
                  isInPast: true,
                },
              },
              isRequired: true,
            },
            {
              name: "base_institution_to_date",
              label: "School End Date",
              type: "month",
              group: 3,
              order: 2,
              validations: {
                date: {
                  maxYearsInPast: 100,
                  maxYearsInFuture: 2,
                },
              },
            },
            {
              name: "base_institution_hs_did_you_graduate",
              label: "Have you or will you graduate?",
              type: "radio",
              options: optionize(["Yes", "No"]),
            },
            {
              name: "base_institution_hs_equivalency_degree",
              label: "Will you receive a high school equivalency degree?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              show: {
                conditions: [
                  {
                    name: "base_institution_hs_did_you_graduate",
                    value: "No",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "base_institution_hs_did_you_graduate",
                    value: "No",
                  },
                ],
              },
            },
          ],
        },
        {
          title: "Your Education History",
          conditionalSection: true,
          description:
            "If you've attended any other high schools/secondary schools or colleges/universities, please enter them below, including any college courses you completed while in high school.",
          fields: [
            {
              name: "institution_attended",
              label:
                "Have you attended other high schools/secondary schools or colleges/universities?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              isRequired: true,
            },
          ],
        },
        {
          title: "Additional Education History",
          nestedSectionsMax: 5,
          nestedSectionTitle: "Additional Education History {{x}}",
          controlSectionName: "institutions",
          isOptional: true,
          hide: {
            conditions: [
              {
                name: "institution_attended",
                value: "No",
              },
              {
                name: "institution_attended",
                value: "",
              },
            ],
            logicalOr: true,
          },
          fields: [
            {
              name: "institution_school_type",
              label: "School Type",
              type: "radio",
              options: optionize(["High School", "College"]),
              prefill: [
                {
                  value: "High School",
                },
              ],
              isRequired: true,
            },
            {
              name: "institution_ceeb_high_school",
              label: "Start typing to search for your school",
              type: "highSchoolSearch",
              isRequired: true,
              show: {
                conditions: [
                  {
                    name: "institution_school_type",
                    value: "High School",
                  },
                ],
              },
            },
            {
              name: "institution_ceeb_college",
              label: "Start typing to search for your college",
              type: "collegeSearch",
              isRequired: true,
              show: {
                conditions: [
                  {
                    name: "institution_school_type",
                    value: "College",
                  },
                ],
              },
            },
            {
              name: "institution_ceeb_name",
              type: "text",
              label: "Institution Name",
              show: {
                conditions: [
                  {
                    name: "institution_ceeb_high_school",
                    value: "I don't see my school",
                  },
                  {
                    name: "institution_ceeb_high_college",
                    value: "I don't see my school",
                  },
                ],
                logicalOr: true,
              },
              isRequired: true,
            },
            {
              name: "institution_from_date",
              label: "Institution Start Date",
              type: "month",
              group: 3,
              order: 1,
              validations: {
                date: {
                  maxYearsInPast: 100,
                  isInPast: true,
                },
              },
              isRequired: true,
            },
            {
              name: "institution_to_date",
              label: "Institution End Date",
              type: "month",
              group: 3,
              order: 2,
              validations: {
                date: {
                  maxYearsInPast: 100,
                  maxYearsInFuture: 2,
                },
              },
            },
            {
              name: "institution_hs_equivalency_degree",
              label: "Will you receive a high school equivalency degree?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              show: {
                conditions: [
                  {
                    name: "institution_hs_did_you_graduate",
                    value: "No",
                  },
                  {
                    name: "institution_school_type",
                    value: "High School",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "institution_hs_did_you_graduate",
                    value: "No",
                  },
                  {
                    name: "institution_school_type",
                    value: "High School",
                  },
                ],
              },
            },
          ],
        },
        {
          title: "High School GPA",
          description: "",
          fields: [
            {
              name: "hs_gpa_cumulative",
              label: "HS GPA Cumulative",
              type: "number",
              isRequired: true,
              validations: {
                value: {
                  max: 100,
                },
              },
              errorText:
                "GPA must be less than (or equal to) maximum GPA possible",
            },
          ],
        },
        {
          title: "High School Counselor",
          description:
            "Sharing this info makes it easier for your counselor to send us your grades and recommendation. Don't forget to tell them you applied to Hollins!",
          fields: [
            {
              name: "counselor_first_name",
              label: "First Name",
              type: "text",
              group: 1,
              order: 1,
            },
            {
              name: "counselor_last_name",
              label: "Last Name",
              type: "text",
              group: 1,
              order: 2,
            },
            {
              name: "counselor_email_address",
              label: "Email Address",
              type: "email",
            },
          ],
        },
        {
          title: "Extracurricular Activities (Optional):",
          conditionalSection: true,
          description:
            "Share the activities you have participated in during high school/secondary school.",
          fields: [
            {
              name: "extracurricular_activity_taken",
              label: "Did you have any extracurricular activites?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              isRequired: true,
            },
          ],
        },
        {
          title: "Extracurricular Activity",
          nestedSectionTitle: "Extracurricular Activity {{x}}",
          nestedSectionItemName: "Extracurricular",
          nestedSectionsMax: 6,
          controlSectionName: "extracurriculars",
          hide: {
            conditions: [
              {
                name: "extracurricular_activity_taken",
                notValue: "Yes",
              },
            ],
          },
          fields: [
            {
              name: "extracurricular_activity_type",
              label: "Activity type",
              type: "activitySearch",
            },
            {
              name: "extracurricular_activity_other",
              label: "Other Activities",
              type: "text",
              show: {
                conditions: [
                  {
                    name: "extracurricular_activity_type",
                    value: "Not Found",
                  },
                ],
              },
            },
            {
              name: "extracurricular_activity_name",
              label: "Name of Activity",
              type: "text",
            },
            {
              name: "extracurricular_activity_position",
              label: "Position/Leadership Description",
              type: "textarea",
            },
            {
              name: "extracurricular_activity_grade_levels",
              label: "During which years did you participate?",
              type: "checkbox",
              options: optionize(["9", "10", "11", "12"]),
            },
            {
              name: "extracurricular_activity_to_continue",
              label: "Do you plan on continuing in college?",
              type: "radio",
              options: optionize([
                ["Yes, I plan to continue this activity in college.", "Yes"],
                [
                  "No, I do not plan to continue this activity in college.",
                  "No",
                ],
              ]),
            },
          ],
        },
        {
          title: "Test Scores (Optional):",
          description:
            "Let us know if you'd like us to consider your scores. Hollins is test optional, so you don't have to submit them.",
          fields: [
            {
              name: "test_scores_considered",
              label: "Do you want to share test scores?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              isRequired: true,
            },
          ],
        },
        {
          title: "SAT",
          nestedSectionTitle: "SAT",
          nestedSectionItemName: "sat",
          nestedSectionsMax: 1,
          show: {
            conditions: [
              {
                name: "test_scores_considered",
                value: "Yes",
              },
            ],
          },
          fields: [
            {
              name: "sat_considered",
              label: "Do you want to provide SAT test scores?",
              type: "radio",
              options: optionize(["Yes", "No"]),
            },
            {
              name: "sat_date_taken",
              label: "SAT Date Taken",
              type: "month",
              show: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_considered",
                    value: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_considered",
                    value: "Yes",
                  },
                ],
              },
              validations: {
                date: {
                  maxYearsInPast: 5,
                  isInPast: true,
                },
              },
            },
            {
              name: "sat_highest_evidence_based_reading_and_writing_score",
              label: "Highest Evidence-Based Reading and Writing Score",
              type: "integer",
              show: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_considered",
                    value: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_considered",
                    value: "Yes",
                  },
                ],
              },
              validations: {
                value: {
                  min: 200,
                  max: 800,
                  divisor: 10,
                },
              },
            },
            {
              name: "sat_highest_math_score",
              label: "Highest Math Score",
              type: "integer",
              show: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_considered",
                    value: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_considered",
                    value: "Yes",
                  },
                ],
              },
              validations: {
                value: {
                  min: 200,
                  max: 800,
                  divisor: 10,
                },
              },
            },
            {
              name: "sat_future_tests",
              label: "Future Test Dates",
              type: "radio",
              options: optionize(["Yes", "No"]),
              show: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_considered",
                    value: "Yes",
                  },
                ],
              },
            },
            {
              name: "sat_future_testing_date_1",
              label: "Future Testing Date 1",
              type: "month",
              show: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_future_tests",
                    value: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_future_tests",
                    value: "Yes",
                  },
                ],
              },
              validations: {
                date: {
                  maxMonthsInPast: 6,
                  maxYearsInFuture: 1,
                },
              },
            },
            {
              name: "sat_future_testing_date_2",
              label: "Future Testing Date 2",
              type: "month",
              group: 2,
              order: 1,
              show: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_future_tests",
                    value: "Yes",
                  },
                ],
              },
              validations: {
                date: {
                  maxMonthsInPast: 6,
                  maxYearsInFuture: 1,
                },
              },
            },
            {
              name: "sat_future_testing_date_3",
              label: "Future Testing Date 3",
              type: "month",
              group: 2,
              order: 2,
              show: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_future_tests",
                    value: "Yes",
                  },
                ],
              },
              validations: {
                date: {
                  maxMonthsInPast: 6,
                  maxYearsInFuture: 1,
                },
              },
            },
          ],
        },
        {
          title: "ACT",
          nestedSectionTitle: "ACT",
          nestedSectionItemName: "act",
          nestedSectionsMax: 1,
          show: {
            conditions: [
              {
                name: "test_scores_considered",
                value: "Yes",
              },
            ],
          },
          fields: [
            {
              name: "act_considered",
              label: "Do you want to provide ACT test scores?",
              type: "radio",
              options: optionize(["Yes", "No"]),
            },
            {
              name: "act_date_taken",
              label: "ACT Date Taken",
              type: "month",
              show: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "act_considered",
                    value: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "act_considered",
                    value: "Yes",
                  },
                ],
              },
              validations: {
                date: {
                  maxYearsInPast: 5,
                  isInPast: true,
                },
              },
            },
            {
              name: "act_highest_composite_score",
              label: "ACT Highest Composite Score",
              type: "integer",
              show: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "act_considered",
                    value: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "act_considered",
                    value: "Yes",
                  },
                ],
              },
              validations: {
                value: {
                  min: 0,
                  max: 36,
                },
              },
            },
            {
              name: "act_future_tests",
              label: "Future Test Dates",
              type: "radio",
              options: optionize(["Yes", "No"]),
              show: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "act_considered",
                    value: "Yes",
                  },
                ],
              },
            },
            {
              name: "act_future_testing_date_1",
              label: "Future Testing Date 1",
              type: "month",
              show: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "act_considered",
                    value: "Yes",
                  },
                  {
                    name: "act_future_tests",
                    value: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "act_considered",
                    value: "Yes",
                  },
                  {
                    name: "act_future_tests",
                    value: "Yes",
                  },
                ],
              },
              validations: {
                date: {
                  maxMonthsInPast: 6,
                  maxYearsInFuture: 1,
                },
              },
            },
            {
              name: "act_future_testing_date_2",
              label: "Future Testing Date 2",
              type: "month",
              group: 3,
              order: 1,
              show: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "act_considered",
                    value: "Yes",
                  },
                  {
                    name: "act_future_tests",
                    value: "Yes",
                  },
                ],
              },
              validations: {
                date: {
                  maxMonthsInPast: 6,
                  maxYearsInFuture: 1,
                },
              },
            },
            {
              name: "act_future_testing_date_3",
              label: "Future Testing Date 3",
              type: "month",
              group: 3,
              order: 2,
              show: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "act_considered",
                    value: "Yes",
                  },
                  {
                    name: "act_future_tests",
                    value: "Yes",
                  },
                ],
              },
              validations: {
                date: {
                  maxMonthsInPast: 6,
                  maxYearsInFuture: 1,
                },
              },
            },
          ],
        },
        {
          title: "Legal Disclosure",
          description:
            "Hollins University does not consider responses to the questions below in our preliminary assessment of a student's academic fit to join our community.",
          fields: [
            {
              name: "misdemeanor_or_felony",
              label: "Have you ever been convicted of a misdemeanor or felony?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              isRequired: true,
            },
            {
              name: "misdemeanor_or_felony_explanation",
              label: "Please provide a brief explanation.",
              type: "textarea",
              show: {
                conditions: [
                  {
                    name: "misdemeanor_or_felony",
                    value: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "misdemeanor_or_felony",
                    value: "Yes",
                  },
                ],
              },
            },
            {
              name: "disciplinary_violation",
              label:
                "Have you ever been found responsible for a disciplinary violation at school that involved academic dishonesty or misconduct where the violation involved physical or mental harm, or potential harm, to another person?",
              type: "radio",
              options: optionize([
                [
                  "Yes, I have been found responsible of a disciplinary violation.",
                  "Yes",
                ],
                [
                  "No, I have not been found responsible of a disciplinary violation.",
                  "No",
                ],
              ]),
              isRequired: true,
            },
            {
              name: "disciplinary_violation_explanation",
              label:
                "Please provide a description of what happened, including what the experience has taught you.",
              type: "textarea",
              show: {
                conditions: [
                  {
                    name: "disciplinary_violation",
                    value: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "disciplinary_violation",
                    value: "Yes",
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      title: "Program Options",
      id: "program-options",
      slug: "program-options",
      description: "Help us get to know more about your college plans.",
      mobileNavTitle: "4. Program Options",
      sections: [
        {
          title: "Academic Interests",
          description: "",
          fields: [
            {
              name: "intended_major",
              label: "Intended Major",
              helperText:
                "Let us know if you have a major in mind. If you don't yet, that's okay. You can select Undecided.",
              type: "select",
              options: optionize(HOLLINS_MAJORS),
              isRequired: true,
            },
          ],
        },
        {
          title: "Essay",
          description: null,
          fields: [
            {
              name: "essay",
              label:
                "How are you uniquely suited to be a member of the Hollins community? In a well-written response of no more than 1,000 words, discuss how your unique strengths, weaknesses, interests, and/or background would make you a good fit for living and studying at Hollins University.",
              type: "textarea",
            },
          ],
        },
        {
          title: "Financial Aid",
          description:
            "There are many sources of financial assistance to help with the cost of college. It's helpful to know if you plan to apply for financial aid so we can provide you with more information. Will you apply for financial aid using the Free Application for Federal Student Aid (FAFSA)? If so, our school code is: 003715",
          fields: [
            {
              name: "financial_aid",
              label: "Do you intend to apply for financial aid?",
              type: "radio",
              options: optionize([
                ["Yes, I intend to apply for financial aid.", "Yes"],
                ["No, I do not intend to apply for financial aid.", "No"],
              ]),
              isRequired: {
                conditions: [
                  {
                    name: "citizenship",
                    notValue: "Not a U.S. Citizen",
                  },
                ],
              },
            },
          ],
        },
        {
          title: "Hollins Community Values",
          fields: [
            {
              name: "community_values_affirmed",
              isRequired: true,
              label:
                "Please affirm the following before you submit your application.",
              type: "checkbox",
              options: [
                {
                  label:
                    "​I acknowledge that I have reviewed the <a href='https://www.hollins.edu/academics/the-honor-code/' target='_blank'>Hollins Honor Code</a> and affirm them as a condition of any offer admission to Hollins University. Furthermore, I understand that failure to adhere to Hollins University Honor Code may result in the revocation of an offer of admission.",
                  value: "Yes",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
}
